body:not(.is-mobile) {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: rgb(136, 136, 140);
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: rgb(85, 85, 90);
  }
  ::-webkit-scrollbar-corner,
  ::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
}
