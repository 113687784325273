.ps {
  position: relative;

  > .ps__rail-x {
    z-index: 99999;
    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }
    &:focus,
    &.ps--clicking {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  > .ps__rail-y {
    z-index: 99999;
    left: auto !important;
    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }
    &:focus,
    &.ps--clicking {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}
