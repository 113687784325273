// Import Fuse core library
@import '@fuse/scss/core';

// Import app.theme.scss
@import 'app/app.theme';

// Import ArcGIS CSS - if using ESRI ArcGIS SDK
// @import 'https://js.arcgis.com/4.25/@arcgis/core/assets/esri/themes/light/main.css';

// asset URL fix for apps hosted on subdirectories / apps with different base HREF
// create paths on this root src folder to refer to the files
// fonts
$muliFontPath: '^assets/styles/google';
$assetPath: '^assets';

// Import Google Material fonts
@import 'assets/styles/material/material-icons.scss';

// Import Google Muli font
@import 'assets/styles/google/muli.scss';

.cursor {
  cursor: pointer;
}
.italic {
  font-style: italic;
}

.account-name {
  background: #607d8b !important;
  margin-top: -8px;
  text-transform: uppercase;
}

.bg-red {
  background: #ff0000;
  color: #fff;
}

.width-100 {
  width: 100%;
}
.width-10 {
  width: 10%;
}

.table-hover {
  // td,th {
  //     padding-left: 2vh !important;
  //     border: 1px solid rgba(255, 255, 255, 0.12);
  // }
  .mat-mdc-header-cell {
    padding: 8px 16px;
  }
  td.mat-column-action,
  th.mat-column-action {
    text-align: center;
  }
  tr:hover {
    background: #525252 !important;
  }
}

.f-right {
  float: right;
}

body {
  font-family: 'Muli', 'Helvetica Neue', 'Arial', sans-serif;
  .custom-snack-bar-panel-error {
    &.mat-mdc-snack-bar-container {
      --mdc-snackbar-supporting-text-color: #fff;
      --mat-mdc-snack-bar-button-color: #fff;
      --mdc-snackbar-container-color: #d32f2f;
      --mat-mdc-button-persistent-ripple-color: rgba(255, 255, 255, 0.05);
    }
  }
}

.mat-mdc-menu-panel.matMenu {
  margin-right: 1vh;
}

.leaflet-marker-shadow {
  display: none;
}
.leaflet-popup-content {
  color: #000;
}

#viewDiv {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

// work-around for mat-select not applying CSS
.mat-select-filter {
  .default-option {
    color: #fff;
  }
  .success-option {
    color: #4bba4e;
  }
  .fail-option {
    color: #f83d30;
  }
  .open-option {
    color: #02a8f0;
  }
  .closed-option {
    color: #ccc;
  }
}
